<template>
  <v-card
    light
    max-width="100%"
    width="400"
    :loading="loading ? 'white' : false"
  >
    <v-card-title
      class="elevation-3 white--text pa-5 justify-center"
      :style="{background: $vuetify.theme.themes.light.primary}"
    >
      <h1 class="text-h4 font-weight-bold mb-2">
        {{ $t('messages.views.auth.login.title') }}
      </h1>
    </v-card-title>

    <o-form
      @submit="submit"
    >
      <v-card-text class="text-center pa-5">
        <o-text-field
          v-model="email"
          :rules="validation.email"
          label="labels.user.email"
          prepend-icon="mdi-email"
          name="email"
          :disabled="loading"
        />

        <o-text-field
          v-model="password"
          type="password"
          :rules="{ required: true }"
          label="labels.user.password"
          prepend-icon="mdi-lock-outline"
          name="password"
          :disabled="loading"
        />

        <router-link
          :to="{ name: 'auth_password_forgotten' }"
          class="grey--text text-decoration-none"
        >
          <span>
            <v-icon
              small
            >
              mdi-lock
            </v-icon>
            {{ $t('messages.views.auth.login.forgotYourPassword') }}
          </span>
        </router-link>
      </v-card-text>

      <v-card-actions class="pa-5">
        <v-btn
          type="submit"
          large
          color="primary"
          class="v-btn--text flex-grow-1"
          :disabled="loading"
        >
          {{ $t('messages.views.auth.login.logIn') }}
        </v-btn>
      </v-card-actions>
    </o-form>
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      validation: require('@validation/entities/user.json'),
      email: '',
      password: '',
      loading: false,
    }),

    methods: {
      submit () {
        this.loading = true;
        this.$apollo.mutate({
          mutation: require('@gql/mutations/user/login.gql'),
          client: 'api-gateway',
          variables: {
            input: {
              email: this.email,
              password: this.password,
            },
          },
        }).then((result) => {
          const user = result.data.login;

          this.$store.commit('auth/setToken', user.token);
          this.$store.commit('auth/login', {
            id: user.id,
            email: user.email,
            roles: user.roles,
          });
        }).catch((e) => {
          if (e.graphQLErrors !== undefined && e.graphQLErrors[0] !== undefined && e.graphQLErrors[0].extensions.code === 'BAD_CREDENTIALS') {
            this.$flash('messages.views.auth.login.error', 'error');
          } else {
            this.$flash(null, 'error');
          }
        }).finally(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
